import { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { createCandidate } from '../graphql/mutations'

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PinDropIcon from '@mui/icons-material/PinDrop';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function GqlForm() {

  // const [petData, setPetData] = useState([])
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    await API.graphql({
      query: createCandidate,
      variables: {
        input: {
          name,
          email,
          message,
        },
      },
    })
  }

  return (
    <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
        }}
    >
      <Card sx={{ p:2, m:2 }} >
        <Box 
          sx={{
            // flexDirection: 'row',
            flexWrap: 'wrap',
            m: 0.5,
            p: 0.5,
          }}  
        >
          <Box 
            sx={{ 
              display:'flex',
              flexDirection: 'column',
              m: 1,
              p: 1,
            }}
          >
            <Typography variant="h6">
              Contáctenos
            </Typography>
            <Typography variant="caption">
              Diligencie el formulario a continuación
            </Typography>
          </Box>
          <List sx={{ mb: 2 }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary="+57 317 378 9070" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="info@aidt.com.co" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PinDropIcon />
                </ListItemIcon>
                <ListItemText primary="Edificio Elemento Piso 16" />
              </ListItemButton>
            </ListItem>
          </List>
          <Box sx={{ 
                display:'flex',
                flexDirection: 'row',
                mb: 2,
              }}          
          >
            <Fab
              sx={{ m: 3 }}
              size="small" 
              color="primary" 
              aria-label="TwitterFabIcon"
              href="https://twitter.com/AidtEnergy"
            >
              <TwitterIcon />
            </Fab>
            <Fab
              sx={{ m: 3 }}
              size="small" 
              color="primary" 
              aria-label="InstagramFabIcon"
              href="https://www.instagram.com/zandradeo/"
            >
              <InstagramIcon />
            </Fab>            
            <Fab
              sx={{ m: 3 }}
              size="small" 
              color="primary" 
              aria-label="LinkedInFabIcon"
              href="https://www.linkedin.com/in/zaimer-andrade-71b4aa50/"
            >
              <LinkedInIcon />
            </Fab>
          </Box>          
          <Box sx={{
                display: 'flex',
                // display: { xs: 'flex', md: 'none' },
                flexDirection: 'column',
                m: 1,
                p: 1,
              }}
              component='form'
              onSubmit={handleSubmit}
              // width='240px'
          >
              <TextField
                  sx={{ mb: 2 }}
                  required
                  variant='standard'
                  name='name'
                  label='Nombre'
                  InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                          <PersonIcon />
                          </InputAdornment>
                      ),
                  }}                            
                  //placeholder='Ingrese un Nombre'
              />
              <TextField
                  sx={{ mb: 2 }}
                  required
                  variant='standard'
                  name='email'
                  label='Correo'
                  type='email'
                  InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                          <MailIcon />
                          </InputAdornment>
                      ),
                  }}                            
                  //placeholder='Ingrese un Nombre'
              />
              <TextField
                  sx={{ mb: 2 }}
                  required
                  variant='standard'
                  name='message'
                  label='Mensaje'
                  multiline={true}
                  rows={4}
                  placeholder='Ingrese su mensaje'
              />
            <Button
              sx={{ m: 2}}
              variant='outlined'
              type='submit'
            >
              Enviar Mensaje
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default GqlForm;
