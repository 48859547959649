import React from 'react';
import ReactDOM from 'react-dom';

import config from './aws-exports';
import { Amplify, I18n } from 'aws-amplify';
import { AmplifyProvider, translations } from '@aws-amplify/ui-react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import App from './App';

import './styles.css';


I18n.putVocabularies(translations);
I18n.setLanguage('es');

const muiTheme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#1976d2',
      //darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    // background: {
    //   paper: '#1976d2'
    // },
  },
  spacing: 8,
  contrastThreshold: 3,
  tonalOffset: 0.2,  
});

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <ThemeProvider theme={muiTheme}>
          <App />
      </ThemeProvider>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);