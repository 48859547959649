/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      name
      email
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        message
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDevicecheck = /* GraphQL */ `
  query GetDevicecheck($id: ID!) {
    getDevicecheck(id: $id) {
      id
      devType
      pqmActiveQtty
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDevicechecks = /* GraphQL */ `
  query ListDevicechecks(
    $filter: ModelDevicecheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevicechecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        devType
        pqmActiveQtty
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDeviceAndTopics = /* GraphQL */ `
  query GetDeviceAndTopics($id: ID!) {
    getDeviceAndTopics(id: $id) {
      id
      deviceIdAddress
      deviceName
      deviceType
      deviceSerialNumber
      simediPointIdAddress
      simediPointName
      simediPointType
      simediPointSerNum
      topic_BasicDashB_Telemetry
      topic_BasicDashB_Sampling
      topic_BasicDashB_ReqConn
      topic_BasicDashB_Connected
      isDeviceConnected
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDeviceAndTopics = /* GraphQL */ `
  query ListDeviceAndTopics(
    $filter: ModelDeviceAndTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceAndTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceIdAddress
        deviceName
        deviceType
        deviceSerialNumber
        simediPointIdAddress
        simediPointName
        simediPointType
        simediPointSerNum
        topic_BasicDashB_Telemetry
        topic_BasicDashB_Sampling
        topic_BasicDashB_ReqConn
        topic_BasicDashB_Connected
        isDeviceConnected
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const devTypeByDate = /* GraphQL */ `
  query DevTypeByDate(
    $devType: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDevicecheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devTypeByDate(
      devType: $devType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        devType
        pqmActiveQtty
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
