import aidtImgBlack from '../images/aidt_logo.jpg'
import aidtImgWhite from '../images/aidt_logo_white.png'

import { 
  Heading, 
  useTheme,
  Image,
  Flex 
} from "@aws-amplify/ui-react";

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Flex 
    direction="column"
    justifyContent="center"
    alignItems="center"
    >
      <Image
            width="30%"
            height="30%"
            objectFit="cover"
            alt="logo"
            src={aidtImgWhite}
            padding={tokens.space.small}
        />
      {/* <Heading level={5} padding={`${tokens.space.m} ${tokens.space.m} 0`}>
        Ingreso a cuenta
      </Heading> */}
    </Flex>
  );
}