import { Image, useTheme, Flex } from "@aws-amplify/ui-react";
import aidtImgBlack from '../images/aidt_logo.jpg'
import aidtImgWhite from '../images/aidt_logo_white.png'

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" backgroundColor={tokens.colors.white[10]}>
        <Image
            width="60%"
            height="60%"
            objectFit="cover"
            alt="logo"
            src={aidtImgWhite}
            padding={tokens.space.medium}
        />
    </Flex>
  );
}