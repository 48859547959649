import { useEffect, useState, useRef, forwardRef } from 'react';

import  Amplify, { API, PubSub, graphqlOperation } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { DateTime } from 'luxon';
import { Chart, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import StreamingPlugin from 'chartjs-plugin-streaming';
import { Chart as GGauge } from "react-google-charts";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import '../App.css';

import { optCurr, optPotApa, optGaugePF, optVol, optPotRea, optGaugeThd } from './ChartConstants';
import DevicesListBox from './DevicesListBox';
import { listDeviceAndTopics, getDeviceAndTopics } from '../graphql/queries';
import { updateDeviceAndTopics } from '../graphql/mutations';

Chart.register(...registerables, StreamingPlugin);

// Apply plugin with configuration
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_AWS_PUBSUB_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT
  }));

const Transition = forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

//Data block
const dataCurr = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Promedio (A)',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
        }
]        
};

const dataPotApa = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total (KVA)',
            data: [],
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1
        }
]        
};

const dataVol = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Promedio (V)',
            data: [],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
        }
]        
};

const dataPotRea = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total (KVAr)',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
        }
]        
};

let timeoutMaxUse = 0;

const DeviceCheckPubSub = () => {

    const [pfValue, setPfValue] = useState([["Label", "Value"],["Tot", 0]]);
    const [thdValue, setThdValue] = useState([["Label", "Value"],["Max", 0]]);
    const [timeOutActivation, setTimeOutActivation] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [connBtnDis, setConnBtnDis] = useState(false);
    const [openTimeoutDialog, setOpenTimeoutDialog] = useState(false);
    const [devAndTopics, setDevAndTopics] = useState(null);
    const [disAutocomp, setDisAutocomp] = useState(false);
    const [openBussyDevDialog, setOpenBussyDevDialog] = useState(false);

    const simediDevices = [];

    // window.addEventListener("online", messageOnLine);
    // window.addEventListener("offline", messageOffLine);

    // const messageOnLine = () => {
    //     console.log("INTERNET CONNECTION")
    // };

    // const messageOffLine = () => {
    //     console.log("INTERNET CONNECTION LOST")
    // };

    useEffect(() => {
        if (devAndTopics === null) {
            const fetchDeviceAndTopics = async () => {
                try {
                    const res = await API.graphql(
                        graphqlOperation(listDeviceAndTopics)
                        //graphqlOperation(listDeviceAndTopics, { id: "some iD" })
                    );
                    return res.data.listDeviceAndTopics.items             
                } catch (error) {
                    console.log(error);
                }
            }
            fetchDeviceAndTopics().then(devAndTopics => {
                devAndTopics.map((dandt, index) => {
                    simediDevices[index]= { 
                        label: dandt.deviceName, 
                        id: parseInt(dandt.deviceIdAddress),
                        registerId: dandt.id,
                        topic_BasicDashB_Connected: dandt.topic_BasicDashB_Connected,
                        topic_BasicDashB_ReqConn: dandt.topic_BasicDashB_ReqConn,
                        topic_BasicDashB_Sampling: dandt.topic_BasicDashB_Sampling,
                        topic_BasicDashB_Telemetry: dandt.topic_BasicDashB_Telemetry
                    }
                });
            });
        }

    return async () => {
        if (devAndTopics !== null) {
            try {
                await PubSub.publish(devAndTopics.topic_BasicDashB_Sampling, { "command": "stop" });
                const updateDeviceConnState = await API.graphql({ query: updateDeviceAndTopics, variables: { input: { id: devAndTopics.registerId, isDeviceConnected: false}}});
                // sub1.unsubscribe();
            } catch (e) {
                console.log (e)
            }
        }
    };
    }, [devAndTopics]); // if [variable-here] then the useEffect function will excecute when variable-here change it value

    const startBtn = async () => {
        timeoutMaxUse = setTimeout( async () => {
            const btnActivation = await stopBtn();
            setTimeOutActivation(true);
            setOpenAlert(true);
        }, 300000); //Production: 300000 - Test:20000
        try {
            await PubSub.publish(devAndTopics.topic_BasicDashB_Sampling, { "command": "start" });
        } catch (e) {
            console.log (e)
        }
    };

    const stopBtn = async () => {
        clearTimeout(timeoutMaxUse);
        try {
            const updateDeviceConnState = await API.graphql({ query: updateDeviceAndTopics, variables: { input: { id: devAndTopics.registerId, isDeviceConnected: false}}});
            //console.log("devAndTopics STATE: ", devAndTopics);
            await PubSub.publish(devAndTopics.topic_BasicDashB_Sampling, { "command": "stop" });
            setPfValue([["Label", "Value"],["Tot", 0]]);
            setThdValue([["Label", "Value"],["Max", 0]]);
            setLoading(false);
            setSuccess(false);
            setConnBtnDis(false);
            setDisAutocomp(false);
        } catch (e) {
            console.log (e)
        }
    };

    const connectBtn = async () => {
        if (!loading) {
            setDisAutocomp(true);
            setSuccess(false);
            setLoading(true);
            setConnBtnDis(true);
            try {
                try {
                    const updateDeviceConnState = await API.graphql({ query: updateDeviceAndTopics, variables: { input: { id: devAndTopics.registerId, isDeviceConnected: true}}});
                } catch (error) {    
                    console.log("SE HA GENERADO UN ERROR AL ACTUALIZAR EL ESTADO DE MONITOREO DEL DISPOSITIVO - ACTUALICE LA PÁGINA EN SU NAVEGADOR", error);
                }
                const pubRes = await PubSub.publish(devAndTopics.topic_BasicDashB_ReqConn, { "command": "ReqConnection" });
                const timeoutID123 = setTimeout(() => {
                    setOpenTimeoutDialog(true);
                }, 10000);
                const subConn = await PubSub.subscribe(devAndTopics.topic_BasicDashB_Connected).subscribe({
                    next: ({ value }) => {
                        if (value.command === 'connected') {
                            clearTimeout(timeoutID123);
                            setSuccess(true);
                            setLoading(false);
                            setConnBtnDis(true);
                            subConn.unsubscribe();
                        };
                    },
                    error: error => console.error(error),
                    complete: () => console.log('Done'),
                });
            } catch (e) {
                console.log (e)
            };
        };
    };    

    const closeTimeoutDialog = () => {
        setOpenTimeoutDialog(false);
        setPfValue([["Label", "Value"],["Tot", 0]]);
        setThdValue([["Label", "Value"],["Max", 0]]);
        setLoading(false);
        setSuccess(false);
        setConnBtnDis(false);
    };

    const deviceSelection = async (data) => {
        if (data !== null) {
            setDevAndTopics(data);
            setConnBtnDis(false);
            try {
                const res = await API.graphql(
                    graphqlOperation(getDeviceAndTopics, { id: data.registerId }));
                if (!res.data.getDeviceAndTopics.isDeviceConnected) {
                    // try {
                    //     const updateDeviceConnState = await API.graphql({ query: updateDeviceAndTopics, variables: { input: { id: data.registerId, isDeviceConnected: true}}});
                    // } catch (error) {    
                    //     console.log("SE HA GENERADO UN ERROR AL ACTUALIZAR EL ESTADO DE MONITOREO DEL DISPOSITIVO - ACTUALICE LA PÁGINA EN SU NAVEGADOR", error);
                    // }

                    const sub1 = PubSub.subscribe(data.topic_BasicDashB_Telemetry).subscribe({
                        next: ({ value }) => {
                            dataCurr.datasets[0].data.push({
                                x: value.fechaHoraLecturaDato,
                                y: value.currAvg //y: value.currAvg
                            });
                            dataPotApa.datasets[0].data.push({
                                x: value.fechaHoraLecturaDato,
                                y: value.AppaPowT //y: value.AppaPowT
                            });
                            setPfValue([["Label", "Value"],["Tot", value.volAvg]]);
                            dataVol.datasets[0].data.push({
                                x: value.fechaHoraLecturaDato,
                                y: value.volAvg //y: value.volLLAvg
                            });
                            dataPotRea.datasets[0].data.push({
                                x: value.fechaHoraLecturaDato,
                                y: value.reactPowT //y: value.reactPowT
                            });
                            setThdValue([["Label", "Value"],["Max", value.volAvg]]);
                        },
                        error: error => console.error(error), // console.log -> {provider: AWSIoTProvider, error: 'Disconnected, error code: 8'}
                        complete: () => console.log('Done'),
                    });    

                    // console.log("Respuesta del Update: ", updateDeviceConnState);
                } else {
                    setOpenBussyDevDialog(true);
                    // console.log("DISPOSITIVO EN USO Y TRANSMITIENDO DATOS A OTRO USUARIO - INTENTE CON EL MONITOREO CON OTRO DISPOSITIVO");
                }
                // console.log("Respuesta Get: ", res.data.getDeviceAndTopics);
                //return res.data.listDeviceAndTopics.items
            } catch (error) {
                console.log(error);
            } 
        };
    };

    const closeBussyDevDialog = () => {
        setOpenBussyDevDialog(false);
        setConnBtnDis(true);
    }

    //console.log("ConnBtnDis:", connBtnDis, " Loading:", loading, " Success:", success, "CheckComm:", checkComm, "OpenTimeoutDialog", openTimeoutDialog, " ", new Date().toUTCString());

    return (
        <Box>
            {timeOutActivation 
                ?   <Collapse in={openAlert}>
                        <Alert
                            severity="info"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>Info</AlertTitle>
                                Se ha alcanzado el tiempo maximo de visualización! - <strong>Cierre esta alerta y Presione nuevamente el botón CONECTAR</strong>
                        </Alert>
                    </Collapse>
                :   <></>
            }
            <Dialog
                open={openTimeoutDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeTimeoutDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Typography variant="h5" component="div" sx={{ color: red[500] }} >
                        Dispositivo sin Conexión
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    Revise la conexión con el Dispositivo.
                    Es posible que el Dispositivo se haya desconectado. Es necesario la conexión
                    con el Dispositivo para visualizar su información.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeTimeoutDialog}>Aceptar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openBussyDevDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeBussyDevDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Typography variant="h5" component="div" sx={{ color: red[500] }} >
                        Dispositivo en Uso
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        El dispositivo está activo y enviando datos a otro usuario.
                        Es necesario esperar hasta que finalice la visualización del otro usuario.
                        Una vez finalice, podrá seleccionar este dispositivo y ver los datos de telemetría.
                        Si lo prefiere, puede seleccionar otro dispositivo e inicar la visualización de datos.  
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeBussyDevDialog}>Aceptar</Button>
                </DialogActions>
            </Dialog>


            <Box>
                <DevicesListBox devSel={deviceSelection} devList={simediDevices} disableAutoCompSend={disAutocomp}/>
            </Box>

            <ButtonGroup disabled={(devAndTopics === null)} variant="contained" aria-label="outlined primary button group" sx={{justifyContent: 'center', mt:2}}>
                <Button sx={{ width: 120 }} disabled={loading || connBtnDis} startIcon={<ElectricalServicesIcon />} onClick={connectBtn} >
                    Conectar
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Button>
                <Button sx={{ width: 120 }} startIcon={<PlayArrowIcon />} disabled={!success} onClick={startBtn}>
                    Iniciar
                </Button>
                <Button sx={{ width: 120 }} startIcon={<StopIcon />} disabled={!success} onClick={stopBtn}>
                    Detener
                </Button>
            </ButtonGroup>
            <Box sx={{ flexGrow: 1, m: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                        <Card variant="outlined">
                            <Line options={optCurr} data={dataCurr} height={340}/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                        <Card variant="outlined">
                            <Line options={optPotApa} data={dataPotApa} height={340}/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Card sx={{ width: 1, height: 1 }} variant="outlined" >
                            <CardHeader subheader="Factor Potencia" />                
                            <GGauge
                                    chartType="Gauge"
                                    width="100%"
                                    height="100%"
                                    margin="0px"
                                    data={pfValue}
                                    options={optGaugePF}
                                />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={5}>
                        <Card variant="outlined">
                            <Line options={optVol} data={dataVol} height={340}/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={5}>
                        <Card variant="outlined">
                            <Line options={optPotRea} data={dataPotRea} height={340}/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Card sx={{ width: 1, height: 1 }} variant="outlined">
                            <CardHeader subheader="THD Corriente" />                
                            <GGauge
                                chartType="Gauge"
                                width="100%"
                                height="100%"
                                margin="0px"
                                data={thdValue}
                                options={optGaugeThd}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default withAuthenticator(DeviceCheckPubSub);