import { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const DevicesListBox = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [simediDevs, setSimediDevs] = useState([]);
    // const [disAutocomp, setDisAutocomp] = useState(false);

    useEffect(() => {
        setSimediDevs(props.devList); //REVIEW later -> useEffect([disAutocomp]) effect over Parent
    }, []);

    return (
        <Autocomplete
            // disabled={disAutocomp}
            disabled={props.disableAutoCompSend}
            disableClearable
            onChange={(event, newValue) => {
                // setDisAutocomp(true);
                // props.enableAutoComp(true);
                props.devSel(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}            
            // disablePortal
            id="combo-box-devices"
            options={simediDevs}
            sx={{ width: 300, m:2 }}
            renderInput={(params) => <TextField {...params} label="Seleccione Dispositivo" variant="standard"/>}
        />
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

export default DevicesListBox;