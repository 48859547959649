/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDevicecheck = /* GraphQL */ `
  mutation CreateDevicecheck(
    $input: CreateDevicecheckInput!
    $condition: ModelDevicecheckConditionInput
  ) {
    createDevicecheck(input: $input, condition: $condition) {
      id
      devType
      pqmActiveQtty
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDevicecheck = /* GraphQL */ `
  mutation UpdateDevicecheck(
    $input: UpdateDevicecheckInput!
    $condition: ModelDevicecheckConditionInput
  ) {
    updateDevicecheck(input: $input, condition: $condition) {
      id
      devType
      pqmActiveQtty
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDevicecheck = /* GraphQL */ `
  mutation DeleteDevicecheck(
    $input: DeleteDevicecheckInput!
    $condition: ModelDevicecheckConditionInput
  ) {
    deleteDevicecheck(input: $input, condition: $condition) {
      id
      devType
      pqmActiveQtty
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDeviceAndTopics = /* GraphQL */ `
  mutation CreateDeviceAndTopics(
    $input: CreateDeviceAndTopicsInput!
    $condition: ModelDeviceAndTopicsConditionInput
  ) {
    createDeviceAndTopics(input: $input, condition: $condition) {
      id
      deviceIdAddress
      deviceName
      deviceType
      deviceSerialNumber
      simediPointIdAddress
      simediPointName
      simediPointType
      simediPointSerNum
      topic_BasicDashB_Telemetry
      topic_BasicDashB_Sampling
      topic_BasicDashB_ReqConn
      topic_BasicDashB_Connected
      isDeviceConnected
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDeviceAndTopics = /* GraphQL */ `
  mutation UpdateDeviceAndTopics(
    $input: UpdateDeviceAndTopicsInput!
    $condition: ModelDeviceAndTopicsConditionInput
  ) {
    updateDeviceAndTopics(input: $input, condition: $condition) {
      id
      deviceIdAddress
      deviceName
      deviceType
      deviceSerialNumber
      simediPointIdAddress
      simediPointName
      simediPointType
      simediPointSerNum
      topic_BasicDashB_Telemetry
      topic_BasicDashB_Sampling
      topic_BasicDashB_ReqConn
      topic_BasicDashB_Connected
      isDeviceConnected
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDeviceAndTopics = /* GraphQL */ `
  mutation DeleteDeviceAndTopics(
    $input: DeleteDeviceAndTopicsInput!
    $condition: ModelDeviceAndTopicsConditionInput
  ) {
    deleteDeviceAndTopics(input: $input, condition: $condition) {
      id
      deviceIdAddress
      deviceName
      deviceType
      deviceSerialNumber
      simediPointIdAddress
      simediPointName
      simediPointType
      simediPointSerNum
      topic_BasicDashB_Telemetry
      topic_BasicDashB_Sampling
      topic_BasicDashB_ReqConn
      topic_BasicDashB_Connected
      isDeviceConnected
      createdAt
      updatedAt
      owner
    }
  }
`;
