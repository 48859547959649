import { useEffect, useState } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { listDevicechecks, devTypeByDate } from '../graphql/queries';
import { onCreateDevicecheck } from '../graphql/subscriptions'
// import * as subscriptions from '../graphql/subscriptions';

import Box from '@mui/material/Box';

import { DateTime } from 'luxon';
import { Chart, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import StreamingPlugin from 'chartjs-plugin-streaming';

import '../App.css';

Chart.register(...registerables, StreamingPlugin);

// ChartJS.defaults.set('plugins.streaming', {
//     duration: 20000
//   });


const DeviceCheck = () => {

    const [deviceCheckList, setDeviceCheckList] = useState([]);

    //Data block
    const data = {
        // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: '# of Votes',
            data: [],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
        }]        
    };

    //Option(config) Block
    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'realtime',
                realtime: {
                    duration: 40000, 
                    delay: 1000,
                    // onRefresh: chart => {
                    //     chart.data.datasets.forEach(dataset => {
                    //         dataset.data.push({
                    //             x: Date.now(),
                    //             y: Math.random()
                    //         });
                    //     });
                    // }
                }              
            }
        },
        plugins: {
            // streaming: {
            //     duration: 20000
            // },
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    useEffect(() => {

        const subscription = API.graphql(
            graphqlOperation(onCreateDevicecheck, {owner: "z.andrade"})
            ).subscribe({
                next: ({ value }) => {
                    data.datasets[0].data.push({
                        x: value.data.onCreateDevicecheck.createdAt,
                        //x: Date.now(),
                        y: value.data.onCreateDevicecheck.pqmActiveQtty
                    })
                    // Chart.update();
                    console.log( value.data.onCreateDevicecheck );
                },
                error: error => console.warn(error)
            });

        // const fetchDeviceCheck = async () => {
        //     try {
        //         // const res = await API.graphql(graphqlOperation(listDevicechecks));
        //         const res = await API.graphql(graphqlOperation(devTypeByDate, {devType: "pm5110v3", sortDirection: "DESC", limit: "10"}));
        //         return res.data.devTypeByDate.items;
        //     } catch (err) {
        //         console.log(err)
        //     }
        // }
    
        // fetchDeviceCheck().then(deviceChecks => { // (deviceChecks) => {
        //     const dataPoints = [];
        //     const devDataPrep = deviceChecks.map(({createdAt, devType, pqmActiveQtty}) => {
        //         const localCreatedAt = DateTime.fromISO(createdAt);
        //         dataPoints.push({x: createdAt, y: pqmActiveQtty});
        //         // dataPoints.push({name: localCreatedAt.toLocaleString(), pqm: devType, qtty: pqmActiveQtty});
        //         // dataPoints.push({name: localCreatedAt.toLocaleString({ month: 'long', day: 'numeric' }), pqm: devType, qtty: pqmActiveQtty});
        //     });
        //     console.log("DATAPOINTS ::::> ", dataPoints);
        //     setDeviceCheckList(dataPoints);
        // })
    }, []);
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 2,
            }}
        >

        <Line options={options} data={data} />

        </Box>
    )
}

export default DeviceCheck;