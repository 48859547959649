import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import { withAuthenticator } from '@aws-amplify/ui-react';

import Container from '@mui/material/Container';

import Admin from './components/Admin';
//import GqlForm from './components/GqlForm';
//import ProtectedUser from './components/ProtectedUser';
import DeviceCheck from './components/DeviceChek';
import DeviceCheckPubSub from './components/DeviceCheckPubSub';
import ContactForm from './components/ContactForm'
import ResponsiveAppBar from './components/ResponsiveAppBar';
import PersistentDrawerSimedi from './components/PersistentDrawerSimedi'

import { Header } from './login/Header';
import { SignInHeader } from './login/SignInHeader';
import { SignInFooter } from './login/SignInFooter';
import { Footer } from './login/Footer';

// import './styles.css';

const App = () => {
    return (
        <Container maxWidth='false' sx={{ ml: 0, mr: 0 }}>            
            <BrowserRouter>
                {/* <ResponsiveAppBar /> */}
                <PersistentDrawerSimedi />
                <Route path="/" exact component={ContactForm} />
                <Route path="/admin" component={Admin} />
                <Route path="/deviceCheck" component={DeviceCheckPubSub} />
            </BrowserRouter>
        </Container>
    );
}

export default withAuthenticator (App, 
    {
        hideSignUp: true,
        components: {
            Header,
            SignIn: {
                Header: SignInHeader,
                Footer: SignInFooter
            },
            Footer
        }
    }
);