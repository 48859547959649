export const optCurr = {
    responsive: true,
    maintainAspectRatio: false, //true
    scales: {
        x: {
            type: 'realtime',
            realtime: {
                duration: 30000, //40000 
                delay: 0, //1000
            },
            pause:false,       
        }
    },
    plugins: {
        // streaming: {
        //     duration: 20000
        // },
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Corriente',
        },
    },
};

export const optPotApa = {
    responsive: true,
    maintainAspectRatio: false, //true    
    scales: {
        x: {
            type: 'realtime',
            realtime: {
                duration: 30000, //40000
                delay: 0, //1000
            },
            pause:false,       
        }
    },
    plugins: {
        // streaming: {
        //     duration: 20000
        // },
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Potencia Aparente',
        },
    },
};

export const optGaugePF = {
    // NO Height and Width -> takes the value from container
    redColor: 'ff99af',
    redFrom: 0, // redFrom: 90,
    redTo: 90, // redTo: 100,
    yellowColor: 'ffe299',
    yellowFrom: 90, // yellowFrom: 75,
    yellowTo: 95, // yellowTo: 90,
    greenColor: 'b4e4e4',
    greenFrom: 95,
    greenTo: 100,
    minorTicks: 5 // minorTicks: 5,
  };

export const optVol = {
    responsive: true,
    maintainAspectRatio: false, //true    
    scales: {
        x: {
            type: 'realtime',
            realtime: {
                duration: 30000, //40000
                delay: 0, //1000
            },
            pause:false,       
        }
    },
    plugins: {
        // streaming: {
        //     duration: 20000
        // },
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Voltaje LL',
        },
    },
};

export const optPotRea = {
    responsive: true,
    maintainAspectRatio: false, //true
    scales: {
        x: {
            type: 'realtime',
            realtime: {
                duration: 30000, //40000
                delay: 0, //1000
            },
            pause:false,       
        }
    },
    plugins: {
        // streaming: {
        //     duration: 20000
        // },
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Potencia Reactiva',
        },
    },
}; 

export const optGaugeThd = {
    // NO Height and Width -> takes the value from container
    redColor: 'ff99af',
    redFrom: 20, // redFrom: 90,
    redTo: 100, // redTo: 100,
    yellowColor: 'ffe299',
    yellowFrom: 10, // yellowFrom: 75,
    yellowTo: 20, // yellowTo: 90,
    greenColor: 'b4e4e4',
    greenFrom: 0,
    greenTo: 10,
    minorTicks: 5 // minorTicks: 5,
  };