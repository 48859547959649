import { useState, useEffect } from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import aidtImgWhite from '../images/aidt_logo_white.png'
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { green, purple } from '@mui/material/colors';
import { useGmailListItemStyles } from '@mui-treasury/styles/listItem/gmail';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const PersistentDrawerSimedi = ({ user, signOut }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);

    // useEffect(() => {
    //   const userFetch = async () => {
    //     let userAuth = await Auth.currentAuthenticatedUser();
    //     const { attributes } = userAuth;
    //     console.log("Atributos Usuario: ", attributes);
    //   };
    //   userFetch();
    // }, []); // if [variable-here] then the useEffect function will excecute when variable-here change it value

    const gmailStyles = useGmailListItemStyles({
      color: '#da3125',
    });
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    return(
      <Box 
        sx={{ display: 'flex' }}
        // sx={{ flexGrow: 1 }}
      >
        <CssBaseline />
        <AppBar position="static" open={open}>
            <Toolbar sx={{  flexDirection:'row', justifyContent: 'space-between' }}>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                SIMEDI
              </Typography>
              <Box>
                <Tooltip title="Usuario">
                  <IconButton
                    color="inherit"
                    onClick={handleOpenUserMenu} 
                    size="large"
                    sx={{ ml: 2 }}
                    aria-controls={Boolean(anchorElUser) ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorElUser) ? 'true' : undefined}                  
                  >
                    <Avatar alt={user.attributes.name} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{user.attributes.name}</ListItemText>
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <VerifiedUserIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{user.username}</ListItemText>
                  </MenuItem>              
                  <MenuItem>
                    <ListItemIcon>
                      <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{user.attributes.email}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={signOut}>
                    <Typography textAlign="center">Salir</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            }
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
            <Card elevation={0} >
                <DrawerHeader>
                  <CardMedia
                    component="img"
                    height='80px'
                    image={aidtImgWhite}
                    alt="aidt logo"
                  />
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
            </Card>
            <Divider />
            <Card elevation={0} >
                <List>
                <ListItem 
                    classes={gmailStyles}
                    button
                    selected={false}
                    component={Link} to="/deviceCheck"
                    onClick={handleDrawerClose}
                  >
                    <ElectricMeterIcon />
                    Dispositivos
                  </ListItem>                
                  <ListItem 
                    classes={gmailStyles}
                    button
                    selected={false}
                    component={Link} to="/"
                    onClick={handleDrawerClose}
                  >
                    <ContactMailIcon />
                    Contacto
                  </ListItem>
                  <ListItem 
                    classes={gmailStyles}
                    button
                    selected={false}
                    component={Link} to="/admin"
                    onClick={handleDrawerClose}
                  >
                    <InboxIcon />
                    Administrador
                  </ListItem>
                </List>
                <Divider />
            </Card>
        </Drawer>
      </Box>
    );
};

export default withAuthenticator (PersistentDrawerSimedi, { hideSignUp: true });